import React from 'react';
import { styled } from '@mui/material/styles';
import { ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar, Box } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';
import Media, { MediaProps } from 'sensortower-components/src/base-components/Media';
import { File, Asset } from 'sensortower-components/src/base-components/Media/Media.types';
import Link from '../Link';
import { sidekick } from '../../utils/sidekick';

export interface SubnavigationItem extends NavigationItemLinkProps {
  media: {
    __typename?: string;
    file?: File;
    fileTablet?: File;
    fileMobile?: File;
    variant?: string;
    title?: string;
    description?: string;
    desktop?: Asset;
    tablet?: Asset;
    mobile?: Asset;
    sidekickLookup?: string;
    sx?: any;
    testId?: string;
    priority?: boolean;
    disableInlineSVG?: boolean;
  };
}

export interface NavigationItemLinkProps {
  __typename?: string;
  id: string;
  variant?: 'group' | 'item' | 'link' | 'button' | 'login' | 'subgroup' | undefined;
  text: string;
  summary: string;
  media?: MediaProps & { __typename?: string };
  title?: string;
  href?: string;
  subNavigation?: SubnavigationItem[];
  sidekickLookup: any;
}

export const NavigationItemLink = ({
  text,
  summary,
  media,
  href,
  variant,
  sidekickLookup
}: NavigationItemLinkProps) => {
  const isStLogin = variant === 'login' && !href?.includes('https://explorer.pathmatics.com/SignIn');
  return (
    <ErrorBoundary>
      <ListItem {...sidekick(sidekickLookup)} data-testid="NavigationItemLink">
        <ListItemButton
          component={Link as any}
          href={href}
          noLinkStyle
          data-testid="NavigationItemLink-linkButton"
          sx={{
            'backgroundColor': isStLogin ? { xs: 'transparent', lg: 'secondary.light' } : 'transparent',
            '&:hover': {
              color: isStLogin ? { xs: 'transparent', lg: 'secondary.dark' } : 'transparent'
            },
            // NOTE: fill only works with hex code
            '& svg': {
              fill: !isStLogin ? { xs: '#FFFFFF', lg: '#006b87' } : { xs: '#FFFFFF', lg: '#20AA9C' }
            },
            '& img[src$=".svg"]': {
              filter: !isStLogin
                ? { xs: '#FFFFFF', lg: 'invert(10%) brightness(80%) hue-rotate(12deg)' }
                : { xs: '#FFFFFF', lg: 'unset' }
            }
          }}>
          <ListItemAvatar>
            <Avatar sx={{ display: { xs: 'none', lg: 'block' }, backgroundColor: media ? 'transparent' : 'auto' }}>
              <Media
                {...media}
                {...sidekick(sidekickLookup?.media)}
                testId="NavigationItemLink-media"
                style={{ width: '100%' }}
              />
            </Avatar>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Indent />
            </Box>
          </ListItemAvatar>
          <ListItemText
            primary={text}
            secondary={summary}
            secondaryTypographyProps={{ sx: { display: { xs: 'none', lg: 'block' } } }}
            data-testid="NavigationItemLink-linkText"
          />
          {variant === 'login' && <LoginArrow />}
        </ListItemButton>
      </ListItem>
    </ErrorBoundary>
  );
};

export default NavigationItemLink;

const Indent = styled(Box, {
  name: 'NavigationItemLink',
  slot: 'MobileIndent'
})<{ variant?: string }>(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  width: 10
}));

const LoginArrow = styled(LoginIcon, {
  name: 'NavigationItemSection',
  slot: 'LoginArrow'
})<{ variant?: string }>(({ theme }) => ({
  width: theme.spacing(3.5),
  marginLeft: theme.spacing(2),
  fill: theme.palette.quartiary?.main
}));
